import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IpAddressService {
  constructor(private http: HttpClient) {}

  public getIpAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get('https://api.ipify.org/?format=json').subscribe({
        next: (res: any) => {
          resolve(res.ip);
        },
        error: (e) => {
          resolve('');
        },
      });
    });
  }
}
