import {Injectable, Inject} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  constructor(@Inject('RECAPTCHA_V3_SITE_KEY') private siteKey: string) {}

  getSiteKey(): string {
    return this.siteKey;
  }

  private waitForGrecaptcha(interval = 50, timeout = 5000): Promise<void> {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();

      const checkGrecaptcha = () => {
        if (typeof grecaptcha !== 'undefined' && grecaptcha.ready) {
          resolve(); // grecaptcha is ready
        } else if (Date.now() - startTime >= timeout) {
          reject('Timeout waiting for grecaptcha to be ready.');
        } else {
          setTimeout(checkGrecaptcha, interval);
        }
      };

      checkGrecaptcha();
    });
  }

  getRecaptchaToken(action: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.waitForGrecaptcha()
        .then(() => {
          grecaptcha.ready(() => {
            grecaptcha
              .execute(this.siteKey, {action: action})
              .then((token: string) => {
                resolve(token); // Return the token
              })
              .catch((error) => {
                console.error('Error executing reCAPTCHA:', error);
                reject(error);
              });
          });
        })
        .catch((error) => {
          console.error('Error waiting for reCAPTCHA:', error);
          reject(error);
        });
    });
  }
}
