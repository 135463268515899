import {Component, Inject, InjectionToken, OnInit} from '@angular/core';
import {RecaptchaService} from './recaptcha-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppComponent implements OnInit {
  title = 'Sage Indonesia - Microtek Informa Solusindo';
  constructor(private recaptchaService: RecaptchaService) {}

  ngOnInit(): void {
    this.loadRecaptchaScript();
  }

  loadRecaptchaScript() {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.recaptchaService.getSiteKey()}`;
    document.head.appendChild(script);
  }
}
